import {AppTable} from "shared/UI/UIKit/Table";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import type {OrganizationsTableProps} from "./types";
import {getOrganizationsTableColumns} from "./columns";

export const OrganizationsTable = (props: OrganizationsTableProps) => {
  if (!props.visible) {
    return null;
  }

  const columns = getOrganizationsTableColumns(props.edit, props.remove);
  return (
    <AppFlex>
      <AppFlex direction="row" justifyContent="flex-end">
        <AppButton onClick={props.create}>
          Создать организацию
        </AppButton>
      </AppFlex>
      <AppTable dataSource={props.dataSource}
                columns={columns} loading={props.loading}
                rowKey={(record) => record.id}/>
    </AppFlex>

  );
};
