import {useEffect, useState} from "react";
import {
  getMunicipalities,
  getOrganizations,
  getRegions
} from "services/api";
import {toOptions} from "shared/helpers/utils/to-options";
import type {
  UserProfileOrganization,
  UserProfileRegion
, UserProfileMunicipality
} from "services/api";
import type {FormInstance} from "antd/lib/form";
import {personalDataLocationFormNames} from "./constants";

export const usePersonalDataLocation = (form: FormInstance) => {
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState<UserProfileRegion[]>([]);
  const [regionId, setRegionId] = useState("");
  const [municipalities, setMunicipalities] = useState<UserProfileMunicipality[]>([]);
  const [municipalityId, setMunicipalityId] = useState("");
  const [organizations, setOrganizations] = useState<UserProfileOrganization[]>([]);

  useEffect(() => {
    getRegions().then(res => {
      setRegions(res);
      setLoading(false);
    });
  }, []);

  const onRegionChange = (value: string) => {
    if (!value) {
      return;
    }
    setMunicipalities([]);
    setOrganizations([]);
    setMunicipalityId("");
    setLoading(true);
    setRegionId(value);
    form.resetFields([personalDataLocationFormNames.municipality, personalDataLocationFormNames.organization]);
    getMunicipalities(value).then(res => {
      setMunicipalities(res);
      setLoading(false);
    });
  };

  const onMunicipalityChange = (value: string) => {
    if (!value) {
      return;
    }
    setMunicipalityId(value);
    setOrganizations([]);
    setLoading(true);
    form.resetFields([personalDataLocationFormNames.organization]);
    getOrganizations(value).then(res => {
      setOrganizations(res);
      setLoading(false);
    });
  };

  return {
    locationLoading: loading,
    regionId,
    municipalityId,
    regionsOptions: toOptions(regions, "id", "name"),
    municipalitiesOptions: toOptions(municipalities, "id", "name"),
    organizationsOptions: toOptions(organizations, "id", "name"),
    organizations,
    municipalities,
    onRegionChange,
    onMunicipalityChange,
    setOrganizations,
    setMunicipalities,
  };
};